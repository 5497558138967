@import '../assets/colors';
@import '../assets/layout';

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content {
  background: transparent;
  text-align: center;

  p {
    margin: 1em 0;
  }
}

.open-view-nav {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 500;
  line-height: 1;
}

.birdview {
  background-color: $color-green;
  position: relative;
  width: 650px;
  overflow: hidden;
  padding: 100px;
  z-index: 500;

  img {
    height: auto;
    width: 100%;
  }

  @media screen and (max-width: $bp-medium) {
    width: 400px;
    padding: 60px;
  }

  @media screen and (max-width: $bp-small) {
    width: 360px;
    padding: 40px;
  }
}

img.camera-icon {
  display: block;
  height: 2.4em;
  width: 2.4em;
}

.scene-menu {
  margin: 0;
  padding: 0;
  list-style: none;

  a {
    background: url('../assets/images/camera.svg') no-repeat center center;
    background-size: 36px 36px;
    display: block;
    height: 36px;
    width: 36px;

    @media screen and (max-width: $bp-medium) {
      background-size: 30px 30px;
      height: 30px;
      width: 30px;
    }

    @media screen and (max-width: $bp-small) {
      background-size: 24px 24px;
      height: 24px;
      width: 24px;
    }
  }

  li:before {
    background: url(../assets/images/camera-path.svg) no-repeat center center;
    background-size: contain;
    content: '';
    position: absolute;
    pointer-events: none;
    opacity: 0;
    height: 250px;
    width: 125px;
    transform: translate(-3px, -105px);

    @media screen and (max-width: $bp-medium) {
      transform: translate(-2px, -83px);
      height: 200px;
      width: 100px;
    }

    @media screen and (max-width: $bp-small) {
      transform: translate(-1px, -61.5px);
      height: 150px;
      width: 75px;
    }
  }

  li.active-camera:before {
    opacity: 1;
  }

  li:nth-child(1) {
    transform: rotate(222deg);
    position: absolute;
    right: 30px;
    bottom: 30px;

    @media screen and (max-width: $bp-medium) {
      right: 20px;
      bottom: 20px;
    }

    @media screen and (max-width: $bp-medium) {
      right: 10px;
      bottom: 10px;
    }
  }

  li:nth-child(2) {
    transform: rotate(42deg);
    position: absolute;
    left: 30px;
    top: 30px;

    @media screen and (max-width: $bp-medium) {
      left: 20px;
      top: 20px;
    }

    @media screen and (max-width: $bp-medium) {
      left: 10px;
      top: 10px;
    }
  }
}
