@import '../assets/fonts';
@import '../assets/colors';
@import '../assets/layout';

.main-nav {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  position: fixed;
  top: 20px;
  left: 0;
  list-style: none;
  z-index: 500;

  li + li {
    padding-left: 10px;

    @media screen and (max-width: $bp-medium) {
      padding-left: 8px;
    }
  }

  li {
    font-size: $font-size_base_l;
    line-height: 1;

    @media screen and (max-width: $bp-medium) {
      font-size: $font-size_base_m;
    }

    @media screen and (max-width: $bp-small) {
      font-size: 11px;
    }

    a {
      border-bottom: 1px solid $color-black;
      font-weight: normal;
      color: $color-black;
      text-decoration: none;
    }
  }

  li a.active {
    opacity: 0.7;
  }

  li.active-building a,
  li a.active {
    font-family: $font-semibold;
    opacity: 1;

    &:hover {
      opacity: 0.7;
    }
  }
}
