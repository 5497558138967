@import '../assets/fonts';
@import '../assets/layout';
@import '../assets/colors';

.table-wrapper {
  background-color: $color-green;
  color: $color-black;
  margin-top: 80px;
  padding: 60px 0 0 0;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: $bp-small) {
    margin-top: 20px;
    padding-top: 80px;
  }

  .camera-scene & {
    margin-top: 0;
    padding-top: 80px;

    @media screen and (max-width: $bp-small) {
      padding-top: 20px;
    }
  }
}

.table-header {
  background-color: $color-green;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  // padding-bottom: 20px;
  position: absolute;
  top: 20px;
  left: 0;
  margin-bottom: 60px;
  width: 100%;

  @media screen and (max-width: $bp-small) {
    margin-bottom: 20px;
    position: relative;
    top: auto;
    left: auto;
    flex-direction: column;
    align-items: flex-start;
  }

  .camera-scene & {
    left: 20px;

    @media screen and (max-width: $bp-small) {
      left: auto;
    }
  }

  h2 {
    border-bottom: 1px solid transparent;
    font-family: $font-regular;
    font-weight: normal;
    // padding-top: 80px;
    text-align: center;

    .camera-scene & {
      padding-top: 0;
    }
  }

  /*
  @media screen and (max-width: $bp-medium) {
    h2 {
      padding-top: 50px;

      .main-wrapper & {
        padding-top: 20px;
      }
    }
  }
  */
}

.before-objects-table-helper {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-left: 20px;
  text-align: left;

  button + button {
    margin-left: 10px;
  }

  @media screen and (max-width: $bp-small) {
    margin-left: 0;
  }

  button {
    border: none;
    border-bottom: 1px solid $color-black;
    color: $color-black;
    font-family: $font-regular;
    padding: 0;
    margin: 0;

    &:hover {
      background-color: transparent;
      color: $color-black;
    }
  }
}

.table-container {
  height: calc(100vh - 100px);
  overflow: scroll;
  position: relative;

  .camera-scene & {
    @media screen and (max-width: $bp-small) {
      height: 50vh;
    }
  }
}

table {
  border-collapse: collapse;
  margin: 0 auto 80px auto;
  max-width: 1680px;
  width: 100%;
}

th {
  border-top: 1px solid $color-black;
  border-bottom: 1px solid $color-black;
  font-weight: bold;

  button {
    background-color: transparent;
    border: none;
    border-radius: 0;
    cursor: pointer;
    font-size: 12px;
    padding: 0;
  }
}

tbody tr:hover {
  background-color: $color-white;
  color: $color-black;
}

tbody tr:hover a {
  color: $color-black;
}

td {
  border-bottom: 1px solid $color-black;
}

th {
  padding: 10px;

  svg {
    font-size: 0.75em;
    margin-left: 0.334em;
  }

  @media screen and (max-width: $bp-small) {
    padding: 5px;
  }
}

th,
td {
  white-space: nowrap;

  .active & {
    background-color: $color-white;
    color: $color-black;
  }

  .active & a {
    background-color: $color-white;
    color: $color-black;
  }
}

tr,
td,
th {
  font-size: 12px;
  vertical-align: top;
  text-align: left;

  a {
    display: block;
    padding: 10px;
    color: $color-black;
    text-decoration: none;

    @media screen and (max-width: $bp-small) {
      padding: 5px;
    }
  }

  @media screen and (max-width: $bp-medium) {
    font-size: 11px;
  }

  @media screen and (max-width: $bp-small) {
    font-size: 10px;
  }
}

th {
  font-size: 12px;
  font-weight: normal;

  @media screen and (max-width: $bp-medium) {
    font-size: 11px;
  }

  @media screen and (max-width: $bp-small) {
    font-size: 10px;
  }

  button {
    color: #000;
    font-size: 12px;
    font-weight: normal;

    &:hover {
      background-color: transparent;
      color: $color-black;
      opacity: 0.7;
    }

    @media screen and (max-width: $bp-medium) {
      font-size: 11px;
    }

    @media screen and (max-width: $bp-small) {
      font-size: 10px;
    }
  }
}

.building-cell a {
  text-transform: capitalize;
}

.objects-in-render-info {
  background: $color-green;
  font-size: $font-size_base_l;
  margin: 0;
  padding: 20px 0 0 0;
  text-align: center;
  width: 100%;

  @media screen and (max-width: $bp-medium) {
    font-size: $font-size_base_m;
  }

  @media screen and (max-width: $bp-small) {
    font-size: 11px;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 10px 20px;
    width: 100%;
  }

  > * + * {
    margin-left: 20px;
  }

  a {
    border-bottom: 1px solid #000;
    color: #000;
    font-size: $font-size_base_l;
    text-decoration: none;

    @media screen and (max-width: $bp-medium) {
      font-size: $font-size_base_m;
    }

    @media screen and (max-width: $bp-small) {
      font-size: 11px;
    }
  }
}
