@import '../assets/colors';
@import '../assets/fonts';
@import '../assets/layout';

.button-goback {
  border: none;
  border-bottom: 1px solid $color-black;
  color: $color-black;
  padding: 0;
  position: fixed;
  top: 50px;
  left: 0;
  z-index: 500;
  text-decoration: none;

  &:hover {
    background-color: transparent;
    color: $color-black;
  }

  @media screen and (max-width: $bp-medium) {
    font-size: $font-size_base_m;
  }

  @media screen and (max-width: $bp-small) {
    font-size: 11px;
  }
}
