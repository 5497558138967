@import '../assets/fonts';
@import '../assets/colors';
@import '../assets/layout';

.buildings-wrapper {
  grid-column: span 2;
  display: grid;
  grid-template-rows: 1fr auto;
  padding-top: 0;

  @media screen and (max-width: $bp-medium) {
    padding: 0;
  }

  @media screen and (max-width: 1300px) {
    display: flex;
    flex-flow: column wrap;
  }
}

.Buildings {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0; // 100px
  position: relative;

  @media screen and (max-width: $bp-medium) {
    padding: 0;
  }

  @media screen and (max-width: 1300px) {
    height: auto;
  }

  svg {
    object-fit: contain;
    object-position: bottom center;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 20;

    @media screen and (max-width: 1300px) {
      transform: translateX(-50%);
      left: 50%;
      height: auto;
      width: 100%;
    }

    polygon,
    rect,
    path {
      fill: rgba($color-white, 0.5);
      stroke: rgba($color-white, 0);
      stroke-width: 0;
      opacity: 0;
      transition: 300ms ease all;
    }

    a.active path,
    path:hover {
      fill: rgba($color-white, 0.5);
      stroke: rgba($color-white, 1);
      opacity: 1;
      stroke-width: 0;
    }
  }

  img {
    object-fit: contain;
    object-position: bottom center;
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 10;

    @media screen and (max-width: 1300px) {
      position: relative;
      left: auto;
      top: auto;
      margin: 0 auto;
      height: auto;
      width: 100%;
    }
  }
}

.buildings-helper {
  display: flex;
  flex: column nowrap;
  align-items: center;
  padding-left: 40px;
  line-height: 1;

  @media screen and (max-width: $bp-medium) {
    align-items: flex-start;
    padding-top: 40px;
    padding-left: 0;
  }

  h2 {
    font-family: $font-regular;
    font-size: 7.5vmax;
    font-weight: 400;
    margin: 0;
    text-align: left;

    @media screen and (max-width: $bp-medium) {
      text-align: center;
      width: 100%;
    }

    a {
      border-bottom: 1px solid $color-black;
      color: $color-black;
      display: block;
      text-decoration: none;
      opacity: 1;

      &.active {
        border-color: #6c6c6c;
        color: #6c6c6c;
      }
    }
  }
}

.buildings-list {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding: 20px;
  width: 100%;
  z-index: 20;

  li {
    color: $color-black;
    font-size: $font-size_base_l;

    @media screen and (max-width: $bp-medium) {
      font-size: $font-size_base_m;
    }

    @media screen and (max-width: $bp-small) {
      font-size: 11px;
    }

    + li {
      margin-left: 0.5em;
    }

    a {
      border-bottom: 1px solid $color-black;
      color: $color-black;
      font-size: $font-size_base_l;
      text-decoration: none;

      @media screen and (max-width: $bp-medium) {
        font-size: $font-size_base_m;
      }

      @media screen and (max-width: $bp-small) {
        font-size: 11px;
      }

      &.active {
        opacity: 0.7;
      }
    }
  }
}
