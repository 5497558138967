@import './assets/colors';
@import './assets/layout';
@import './assets/global.scss';

:root {
  --color-forsale: $color-forsale;
  --color-sold: $color-sold;
  --color-reserved: $color-reserved;
}

body {
  background-color: $color-green;
  color: $color-black;
  font-size: $font-size_base_l;
}

.main-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow: hidden;
  height: 100vh;

  @media (max-width: $bp-medium) {
    grid-template-columns: 1fr;
    // grid-template-rows: 50vh 50vh;
  }
}
