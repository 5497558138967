@import '../assets/fonts';
@import '../assets/colors';
@import '../assets/layout';

.SingleObject {
  background-color: $color-green;
  color: $color-black;
  display: grid;
  grid-template-columns: 250px 150px 1fr;
  grid-column-gap: 100px;
  grid-row-gap: 30px;
  padding: 100px 0 20px 0;
  margin: 0 auto;
  // align-items: flex-start;
  // min-height: 100vh;

  @media screen and (max-width: $bp-medium) {
    grid-template-columns: 200px 150px 1fr;
    grid-column-gap: 50px;
  }

  @media screen and (max-width: $bp-small) {
    grid-template-columns: 1fr;
  }

  a {
    color: #fe5000;
  }

  a.button,
  button {
    background-color: #fe5000;
    border: none;
    border-radius: 0;
    color: $color-white;
    font-size: 20px;
    padding: 0.5em 1em;
    cursor: pointer;
    min-width: 300px;
    text-align: center;

    &:hover,
    &:focus {
      opacity: 0.7;
    }

    &[disabled] {
      opacity: 0.5;
    }

    @media screen and (max-width: $bp-medium) {
      font-size: $font-size_base_l;
      min-width: 220px;
    }
  }

  h1 {
    grid-column: span 3;
    font-family: $font-semibold;
    font-size: 40px;

    @media screen and (max-width: $bp-medium) {
      font-size: 30px;
    }

    @media screen and (max-width: $bp-small) {
      grid-column: 1;
      font-size: 20px;
    }
  }

  h2 {
    font-family: $font-regular;
    font-size: 20px;

    @media screen and (max-width: $bp-medium) {
      font-size: $font-size_base_l;
    }
  }
}

.SingleObject-main {
  display: flex;
  flex-flow: column nowrap;

  a {
    color: $color-black;
    font-size: 20px;

    @media screen and (max-width: $bp-medium) {
      font-size: $font-size_base_l;
    }
  }
}

.SingleObject-summary {
  font-size: 20px;
  display: flex;
  flex-flow: column nowrap;
  // margin-top: 20px;
  // max-width: 600px;
  list-style: none;
  line-height: 1.334;

  li:first-child + li {
    margin-top: 0.5em;
  }

  @media screen and (max-width: $bp-medium) {
    font-size: $font-size_base_l;
  }

  & + p {
    margin-top: 20px;
  }
}

.floorplan a {
  display: flex;
  flex-flow: column nowrap;

  img {
    margin: 10px 0;
    max-width: 170px;
    height: auto;
  }
}

.InterestForm {
  // margin: 30px 0;
  // padding-top: 40px;
  position: relative;
  min-height: 100px;

  .loading-wrapper {
    height: 100px;
  }
}

.InterestForm-fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  list-style: none;
  margin-top: 10px;

  @media screen and (max-width: $bp-small) {
    grid-template-columns: 1fr;
  }
}

.InterestForm-field-item {
  display: flex;
  flex-flow: column nowrap;

  input[type='email'],
  input[type='text'] {
    border: none;
    font-family: $font-regular;
    font-size: 20px;

    @media screen and (max-width: $bp-medium) {
      font-size: $font-size_base_l;
    }
  }

  &:last-child {
    label {
      font-size: 14px;
    }
  }

  label {
    font-family: $font-regular;
    font-size: 20px;

    a {
      color: $color-black;
    }

    @media screen and (max-width: $bp-medium) {
      font-size: $font-size_base_l;
    }
  }

  &:last-child {
    grid-column: span 2;

    @media screen and (max-width: $bp-small) {
      grid-column: span 1;
    }
  }

  input[type='email'],
  input[type='text'] {
    padding: 0.5em 1em;
    width: 100%;
  }
}

.InterestForm-footer {
  margin-top: 20px;
}
