@import './fonts';
@import './layout';

/*
@font-face {
  font-family: 'MetricWeb';
  src: url('./fonts/MetricWeb-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'MetricWeb-semibold';
  src: url('./fonts/MetricWeb-Semibold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
*/

@font-face {
  font-family: 'Helvetica Neue LT W05_45 Light';
  src: url('./fonts/f9c5199e-a996-4c08-9042-1eb845bb7495.woff2') format('woff2');
}

@font-face {
  font-family: 'Helvetica Neue LT W05_55 Roman';
  src: url('./fonts/08b57253-2e0d-4c12-9c57-107f6c67bc49.woff2') format('woff2');
}

@font-face {
  font-family: 'Helvetica Neue LT W01_75 Bold';
  src: url('./fonts/1f4274cd-2674-481e-9023-69e33ffca161.woff2') format('woff2');
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: $font-regular;
  font-weight: normal;
  font-size: $font-size_base_l;
}

h1 {
  font-family: $font-semibold;
  font-weight: normal;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-semibold;
  font-size: $font-size_base_l;
  font-weight: normal;
  margin: 0;

  @media screen and (max-width: $bp-medium) {
    font-size: $font-size_base_m;
  }

  @media screen and (max-width: $bp-small) {
    font-size: 11px;
  }
}

p {
  margin: 0;
}

strong {
  font-family: $font-semibold;
  font-weight: normal;
}

figure,
ul,
ol {
  padding: 0;
  margin: 0;
}

.button,
button,
a.button {
  background-color: transparent;
  border: 1px solid $color-black;
  border-radius: 0;
  color: $color-black;
  font-size: $font-size_base_l;
  font-family: $font-semibold;
  font-weight: normal;
  display: inline-block;
  padding: 0.25em 0.5em;
  text-align: center;
  text-decoration: none;

  &:hover,
  &:focus {
    background-color: $color-black;
    color: $color-white;
    cursor: pointer;
  }

  @media screen and (max-width: $bp-medium) {
    font-size: $font-size_base_m;
  }

  @media screen and (max-width: $bp-small) {
    font-size: 11px;
  }
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}
