@import '../assets/colors';
@import '../assets/layout';

.camera-scene {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0;
  grid-column: span 2;
  grid-auto-rows: auto;
  // padding-top: 80px;

  @media screen and (max-width: $bp-small) {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    // padding-top: 60px;
  }

  .table-wrapper {
    padding-left: 20px;

    @media screen and (max-width: $bp-small) {
      padding-left: 0;
    }
  }
}

.scene-container {
  margin-top: 80px;
  position: relative;

  @media screen and (max-width: $bp-small) {
    margin-top: 60px;
  }
}

.Scene {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
  position: relative;

  img {
    display: block;
    height: auto;
    margin: auto;
    width: 100%;
  }

  @media screen and (max-width: $bp-medium) {
    padding: 0;
    height: auto;
    width: 100%;
  }
}

.scene-svg {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;

  @media screen and (max-width: $bp-medium) {
    padding: 0;
  }

  polygon {
    fill: rgba(135, 135, 135, 0.7);
    stroke: rgb(50, 50, 50);
    stroke-width: 0;
    opacity: 0;
  }

  rect,
  path {
    fill: rgba(100, 100, 100, 0);
    stroke: transparent;
    stroke-width: 1px;
    opacity: 1;
    transition: 300ms ease all;

    &:hover,
    &.active {
      stroke: #fff;
    }
  }

  .reserved {
    fill: var($color-reserved, 0);

    .show-status-for-objects & {
      fill: rgba($color-reserved, 0.4);
      stroke: #fff;
    }

    &.active,
    &:hover {
      fill: rgba($color-reserved, 0.8);
      stroke: #fff;
    }
  }

  .sold-not-admitted {
    fill: rgba($color-reserved, 0);

    .show-status-for-objects & {
      fill: rgba($color-reserved, 0.4);
      stroke: #fff;
    }

    &.active,
    &:hover {
      fill: rgba($color-reserved, 0.8);
      stroke: #fff;
    }
  }

  .sold {
    fill: rgba($color-sold, 0);

    .show-status-for-objects & {
      fill: rgba($color-sold, 0.4);
      stroke: #fff;
    }

    &.active,
    &:hover {
      fill: rgba($color-sold, 0.8);
      stroke: #fff;
    }
  }

  .for-sale {
    fill: rgba($color-forsale, 0);

    .show-status-for-objects & {
      fill: rgba($color-forsale, 0.4);
      stroke: #fff;
    }

    &.active,
    &:hover {
      fill: rgba($color-forsale, 0.8);
      stroke: #fff;
    }
  }
}

.prev-scene,
.next-scene {
  background: transparent;
  border: none;
  color: $color-white;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  padding: 0;
  position: absolute;
  top: 50%;

  &:hover {
    background-color: transparent;
    color: $color-white;

    svg {
      opacity: 0.5;
    }
  }
}

.prev-scene {
  left: 20px;

  @media screen and (max-width: $bp-medium) {
    left: 10px;
  }
}

.next-scene {
  right: 20px;

  @media screen and (max-width: $bp-medium) {
    right: 10px;
  }
}
